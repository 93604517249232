<template>
	<BaseLoading
		v-if="isLoading"
		is-full-page
	/>
	<div v-else>
		<div class="text-right">
			<router-link
				:to="{ name: 'CreateNewCategory' }"
				class="link-button"
			>
				<CButton class="btn-create" color="secondary"	data-id="create-category">
					<CIcon class="icon-left" name="cil-plus" />
					Create
				</CButton>
			</router-link>
		</div>
		<CRow>
			<CCol lg="4" class="categories-column">
				<CategoriesList
					ref="category-list-1"
					:list="categoryLevelOneList"
					:is-draggable="!isSorting"
					label="Level 1"
					class="categories-list"
					@onSelect="(id) => handleSelectCategory(id, CATEGORY_LEVEL.ONE)"
					@onDragged="(ids) => handleUpdateOrderCategory(ids, 'category-list-1')"
				/>
			</CCol>
			<CCol lg="4" class="categories-column">
				<CategoriesList
					ref="category-list-2"
					:list="categoryLevelTwoList"
					:is-draggable="!isSorting"
					label="Level 2"
					class="categories-list"
					@onSelect="(id) => handleSelectCategory(id, CATEGORY_LEVEL.TWO)"
					@onDragged="(ids) => handleUpdateOrderCategory(ids, 'category-list-2')"
				/>
			</CCol>
			<CCol lg="4" class="categories-column">
				<CategoriesList
					ref="category-list-3"
					:list="categoryLevelThreeList"
					:is-draggable="!isSorting"
					label="Level 3"
					class="categories-list"
					@onDragged="(ids) => handleUpdateOrderCategory(ids, 'category-list-3')"
				/>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { CATEGORY_LEVEL } from '../enums/categories';
import CategoriesList from '../components/CategoriesList.vue';
import { getActiveItemChildren } from '../assets/js/helpers';
import { transformedActive } from '../assets/js/transform/transform';

export default {
	name: 'CategoriesPage',
	components: {
		CategoriesList,
	},
	data() {
		return {
			CATEGORY_LEVEL,
		};
	},
	computed: {
		...mapState('categories', {
			list: 'list',
			create: 'create',
			delete: 'delete',
			sort: 'sort',
		}),
		...mapGetters({
			categoryLists: 'categories/categoryLists',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		isSorting() {
			return this.sort.isLoading;
		},
		isSortError() {
			return this.sort.error;
		},
		isCreateSuccess() {
			return this.create.isSuccess;
		},

		// Category list
		categoryLevelOneList() {
			// Set isActive
			const activeId = this.list.activeIds[CATEGORY_LEVEL.ONE];
			return this.categoryLists.map((category) => transformedActive(category, activeId));
		},
		categoryLevelTwoList() {
			// Find level two list
			const list = getActiveItemChildren(this.categoryLevelOneList);

			// Set isActive
			const activeId = this.list.activeIds[CATEGORY_LEVEL.TWO];
			return list.map((category) => transformedActive(category, activeId));
		},
		categoryLevelThreeList() {
			return getActiveItemChildren(this.categoryLevelTwoList);
		},
	},
	created() {
		this.getCategories();
	},
	mounted() {
		// Display toast create/delete success
		if (this.isCreateSuccess) {
			this.showToast({
				content: this.$t('page.categories.create.successMessage'),
				header: this.$t('global.successMessageTitle'),
				type: 'success',
			});

			setTimeout(() => this.clearCreateError(), 3000);
		} else if (this.delete.isSuccess) {
			this.showToast({
				content: this.$t('page.categories.delete.successMessage'),
				header: this.$t('global.successMessageTitleDelete'),
			});

			setTimeout(() => this.clearDeleteError(), 3000);
		}
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			getCategories: 'categories/getCategories',
			selectCategory: 'categories/selectCategory',
			postUpdateOrderCategory: 'categories/postUpdateOrderCategory',
			clearCreateError: 'categories/clearCreateError',
			clearDeleteError: 'categories/clearDeleteError',
		}),

		handleSelectCategory(categoryId = null, categoryLevel = CATEGORY_LEVEL.ONE) {
			if (categoryId) {
				this.selectCategory({ categoryId, categoryLevel });
			}
		},
		async handleUpdateOrderCategory(categoryIds = [], ref = null) {
			await this.postUpdateOrderCategory(categoryIds);

			if (this.isSortError && this.$refs[ref]) {
				// Revert sorting
				this.$refs[ref].handleRevertSorting();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.categories {
	// .categories-column
	&-column {
		margin-bottom: rem(32);

		&:last-child {
			margin-bottom: 0;
		}

		@include lg {
			margin-bottom: 0;
		}
	}

	// .categories-list
	&-list {
		height: 100%;
		display: flex;
		flex-direction: column;

		::v-deep .category-list {
			height: 100%;
		}
	}
}

.btn-create {
	min-width: rem(132);
	margin-bottom: rem(20);
}
</style>
