<template>
	<div>
		<p class="category-list-label typo-label">
			{{ label }}
		</p>
		<draggable
			:value="dummyList"
			:disabled="!isDraggable"
			tag="ul"
			ghost-class="is-placeholder-item"
			class="category-list"
			data-test-id="category-list"
			@input="handleUpdateOrder"
		>
			<CategoriesListItem
				v-for="category in dummyList"
				:key="category.id"
				:data="category"
				class="category-list-item"
				data-test-id="category-list-item"
				@onSelect="(id) => $emit('onSelect', id)"
			/>
		</draggable>
	</div>
</template>

<script>
import draggable from 'vuedraggable';
import CategoriesListItem from './CategoriesListItem.vue';

export default {
	name: 'CategoriesList',
	components: {
		draggable,
		CategoriesListItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			default: '',
		},
		isDraggable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			dummyList: [],
		};
	},
	watch: {
		list: {
			immediate: true,
			handler(list) {
				this.dummyList = list;
			},
		},
	},
	methods: {
		handleUpdateOrder(sortedList) {
			// Update dummy list
			this.dummyList = sortedList;

			// Emit for call API
			const ids = sortedList.map((list) => list.id);
			this.$emit('onDragged', ids);
		},
		handleRevertSorting() {
			this.dummyList = this.list;
		},
	},
};
</script>

<style lang="scss" scoped>
.category-list {
	min-height: rem(36);
	border-radius: 4px;
	background-color: $color-gray-100;
	list-style-type: none;
	padding: 2px;
	margin: 0;

	// .category-list-label
	&-label {
		color: $color-black-45;
		margin-bottom: rem(8);
	}

	// .category-list-item
	&-item {
		margin-bottom: 2px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>
