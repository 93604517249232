<template>
	<li
		:class="['category-list-item', {
			'is-active': data.isActive,
			'has-child': data.hasChild
		}]"
		@click="handleSelectItem"
	>
		<span class="category-list-item-id">{{ data.id }}</span>
		<p
			class="category-list-item-name typo-body-2"
			data-test-id="category-list-item-name"
		>
			{{ data.name }}
		</p>
		<router-link
			:to="{
				name: 'CategoryEdit',
				params: { id: data.id }
			}"
			:class="`category-list-item-edit-button typo-helper-text ${data.name}`"
			@click.native.stop
		>
			Edit
		</router-link>
		<FontAwesomeIcon
			v-if="data.hasChild"
			:icon="['fas', 'chevron-right']"
			class="category-list-item-arrow"
		/>
	</li>
</template>

<script>
export default {
	name: 'CategoriesListItem',
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	methods: {
		handleSelectItem() {
			if (this.data.hasChild) {
				this.$emit('onSelect', this.data.id);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.category-list-item {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	padding: rem(6) rem(26) rem(6) rem(12);
	background-color: $color-white;
	cursor: move;
	border-radius: 4px;
	transition: $transition-duration background-color;

	// when active
	&.is-active {
		background-color: $color-primary-25;
	}

	// when sorting
	&.is-placeholder-item {
		opacity: 0.5;
	}

	// .category-list-item-name
	&-name {
		@include ellipsisOneline;

		flex: 1;
		margin: 0 rem(16) 0 0;
	}

	// .category-list-item-edit-button
	&-edit-button {
		@include link-color($color-black-25, $color-hypertext);

		min-width: rem(21);
	}

	// .category-list-item-arrow
	&-arrow {
		position: absolute;
		top: 50%;
		right: 4px;
		width: rem(18);
		height: rem(12);
		margin-top: rem(-6); // height/2
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-black-25;
	}

	// .category-list-item-id
	&-id {
		color: $color-black-45;
		display: inline-block;
		width: rem(50);
	}
}
</style>
